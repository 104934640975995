import { number } from 'prop-types'
import React from 'react'

const GlossaryNameListingModule = ({ glossaries, startingLetter }) => {

    
    return(
        <section className='my-10'>
            <h2 className='text-3xl pb-3 border-b w-1/3'  id={startingLetter}>{startingLetter}</h2>
            <div className='md:grid md:grid-cols-2 xl:grid-cols-3 mt-5'>
                {
                    glossaries.map(glossary => {  
                        if (glossary.title.charAt(0) === startingLetter) {
                            return (
                                <ul className='purplebullets' key={glossary.id}>
                                    <li>
                                        <a href={glossary.uri} className='homepage-mini-heading text-indigo-100 hover:text-orange'>{glossary.title}</a>
                                    </li>
                                </ul>
                            );
                        }
                        return null;
                    })
                }
            </div>
        </section>
    )
}

export default GlossaryNameListingModule